.animated-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 40px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: rgb(2, 157, 2);
  box-shadow: 0 0 0 2px rgb(2, 157, 2);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button2 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 40px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: rgb(243, 16, 16);
  box-shadow: 0 0 0 2px rgb(243, 16, 16);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button svg {
  position: absolute;
  width: 24px;
  fill: rgb(2, 157, 2);
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.animated-button2 svg {
  position: absolute;
  width: 24px;
  fill: rgb(243, 16, 16);
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.animated-button .arr-1 {
  right: 16px;
}
.animated-button2 .arr-1 {
  right: 16px;
}

.animated-button .arr-2 {
  left: -25%;
}
.animated-button2 .arr-2 {
  left: -25%;
}
.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgb(2, 157, 2);
  border-radius: 50%;
  /* padding: 10px 0px; */
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button2 .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgb(243, 16, 16);
  border-radius: 50%;
  /* padding: 10px 0px; */
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.animated-button2 .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: white;
  border-radius: 12px;
  padding: 6px 40px;
}
.animated-button2:hover {
  box-shadow: 0 0 0 12px transparent;
  color: white;
  border-radius: 12px;
  padding: 6px 40px;
}

.animated-button:hover .arr-1 {
  right: -25%;
}

.animated-button2:hover .arr-1 {
  right: -25%;
}

.animated-button:hover .arr-2 {
  left: 16px;
}
.animated-button2:hover .arr-2 {
  left: 16px;
}
.animated-button:hover .text {
  transform: translateX(12px);
}
.animated-button2:hover .text {
  transform: translateX(12px);
}

.animated-button:hover svg {
  fill: white;
}
.animated-button2:hover svg {
  fill: white;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px rgb(2, 157, 2);
}
.animated-button2:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px rgb(243, 16, 16);
}
.animated-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}
.animated-button2:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}

.disabled-animated {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 40px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: rgb(146, 146, 146);
  box-shadow: 0 0 0 2px rgb(146, 146, 146);
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
