.formInputs{
    width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  font-size: 16px;
  color: #fff;
  padding: 8px;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}

.formInputs:focus {
    box-shadow: inset 1px 1px 2px #BABECC,
                inset -1px -1px 2px #ffffff73;
  }
.formInputs::placeholder{
    color: #fff;
}
.formBtn{
    background: transparent;
    box-shadow: 0px 0px 5px rgba(9, 9, 121, 1),
             0px 0px 10px rgba(0, 91, 255, 1);

}
.formBtn:focus {
    color: white;
    box-shadow: inset 0px 0px 5px rgba(9, 9, 121, 1),
               inset 0px 0px 10px rgba(0, 91, 255, 1);
  }